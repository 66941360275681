// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-hindi-index-js": () => import("./../../../src/pages/blog/hindi/index.js" /* webpackChunkName: "component---src-pages-blog-hindi-index-js" */),
  "component---src-pages-blog-hindi-strapi-articles-slug-js": () => import("./../../../src/pages/blog/hindi/{StrapiArticles.slug}.js" /* webpackChunkName: "component---src-pages-blog-hindi-strapi-articles-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-strapi-articles-slug-js": () => import("./../../../src/pages/blog/{StrapiArticles.slug}.js" /* webpackChunkName: "component---src-pages-blog-strapi-articles-slug-js" */),
  "component---src-pages-booking-index-js": () => import("./../../../src/pages/booking/index.js" /* webpackChunkName: "component---src-pages-booking-index-js" */),
  "component---src-pages-booking-strapi-services-slug-js": () => import("./../../../src/pages/booking/{StrapiServices.slug}.js" /* webpackChunkName: "component---src-pages-booking-strapi-services-slug-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-cancellation-refund-policy-js": () => import("./../../../src/pages/cancellation-refund-policy.js" /* webpackChunkName: "component---src-pages-cancellation-refund-policy-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-consultancy-js": () => import("./../../../src/pages/consultancy.js" /* webpackChunkName: "component---src-pages-consultancy-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-expertises-strapi-industry-expertises-slug-js": () => import("./../../../src/pages/industry-expertises/{StrapiIndustryExpertises.slug}.js" /* webpackChunkName: "component---src-pages-industry-expertises-strapi-industry-expertises-slug-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-2-js": () => import("./../../../src/pages/products2.js" /* webpackChunkName: "component---src-pages-products-2-js" */),
  "component---src-pages-products-hindi-strapi-products-slug-js": () => import("./../../../src/pages/products/hindi/{StrapiProducts.slug}.js" /* webpackChunkName: "component---src-pages-products-hindi-strapi-products-slug-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-strapi-products-slug-js": () => import("./../../../src/pages/products/{StrapiProducts.slug}.js" /* webpackChunkName: "component---src-pages-products-strapi-products-slug-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-service-business-to-business-js": () => import("./../../../src/pages/service/business-to-business.js" /* webpackChunkName: "component---src-pages-service-business-to-business-js" */),
  "component---src-pages-service-business-to-consumer-js": () => import("./../../../src/pages/service/business-to-consumer.js" /* webpackChunkName: "component---src-pages-service-business-to-consumer-js" */),
  "component---src-pages-service-index-js": () => import("./../../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-services-hindi-strapi-services-slug-js": () => import("./../../../src/pages/services/hindi/{StrapiServices.slug}.js" /* webpackChunkName: "component---src-pages-services-hindi-strapi-services-slug-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-overview-js": () => import("./../../../src/pages/services/overview.js" /* webpackChunkName: "component---src-pages-services-overview-js" */),
  "component---src-pages-services-strapi-services-slug-js": () => import("./../../../src/pages/services/{StrapiServices.slug}.js" /* webpackChunkName: "component---src-pages-services-strapi-services-slug-js" */),
  "component---src-pages-termite-control-in-delhi-ncr-js": () => import("./../../../src/pages/termite-control-in-delhi-ncr.js" /* webpackChunkName: "component---src-pages-termite-control-in-delhi-ncr-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

